/**
 * Product2 javascript file
 * for product edit/create unit page
 */

Product2Edit.Unit = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        units: [] // Data to update in product_unit db, Will update in receiveData()
    },

    /** All render function have reference to [Product2Edit.data.product.units] not in [this.data] */

    unitsOptionHTML: function (selectID) {
        var _html = '';

        this.units(true, selectID).forEach(function (unit) {
            _html += ('<option value="' + unit.id + '" ' + (selectID === unit.id ? 'selected ' : ' ') + (unit.disable ? 'disabled' : '') + '>' + unit.name + '</option>');
        });

        return _html;
    },
    checkValueMinOne(input) {
        if (input.value < 1) {
            input.value = 1;
        }
    },
    /**
     * @return {string}
     */
    tableRowHTML: function (data, index) {
        return '<tr class="' + (data.new ? 'checked new' : '') + '" data-id="' + data.pivot.id + '" data-company="' + data.pivot.company_id + '" id="' + (data.new ? 'new-unit' : 'old-unit') + '">\n' +
            '    <td>\n' +
            '        <select name="unit_id" class="form-control input-sm" ' + (data.new ? '' : 'style="display: none"') + '>\n' +
            '            ' + this.unitsOptionHTML(data.pivot.unit_id) + '\n' +
            '        </select>\n' +
            (data.new ? '' : '<h2 style="display:inline-block; min-width: 192px">' + this.unit(data.pivot.unit_id).name + '</h2>') +
            '    </td>\n' +
            '    <td>\n' +
            '        <input type="number" min="1" name="quantity_of_smallest_unit" value="' + data.pivot.quantity_of_smallest_unit + '" ' +
            '           class="form-control input-sm text-right" disabled>\n' +
            '    </td>\n' +
            '    <td>\n' +
            '        <input type="text" name="volume" value="' + (data.pivot.volume ? data.pivot.volume : '') + '" class="form-control input-sm text-right" disabled>\n' +
            '    </td>\n' +
            '    <td>\n' +
            '        <div class="input-group">' +
            '            <span class="input-group-addon">฿</span>' +
            '            <input type="number" min="0" name="label_price" value="' + (data.pivot.label_price ? data.pivot.label_price : 0) + '"' +
            '                class="form-control input-sm text-right" disabled>\n' +
            '        </div>' +
            '    </td>\n' +
            '    <td style="text-align: center; padding-top: 15px">\n' +
            '        <input type="checkbox" name="is_retail" ' + (data.not_retail ? '' : 'checked') + '>\n' +
            '    </td>\n' +
            '    <td style="text-align: right"></td>\n' +
            '</tr>';
    },

    tableRowOwnerHTML: function (data, index) {
        return '<tr class="' + (data.new ? 'checked new' : '') + '" data-id="' + data.pivot.id + '" data-company="' + data.pivot.company_id + '" id="' + (data.new ? 'new-unit' : 'old-unit') + '">\n' +
            '    <td>\n' +
            '        <select name="unit_id" class="form-control input-sm" ' + (data.new ? '' : 'style="display: none"') + '>\n' +
            '            ' + this.unitsOptionHTML(data.pivot.unit_id) + '\n' +
            '        </select>\n' +
            (data.new ? '' : '<h2 style="display:inline-block; min-width: 192px">' + this.unit(data.pivot.unit_id).name + '</h2>') +
            '    </td>\n' +
            '    <td>\n' +
            (data.pivot.is_smallest_unit ?
                    '<input type="number" name="quantity_of_smallest_unit" value="'+data.pivot.quantity_of_smallest_unit+'" class="form-control input-sm text-right" disabled title="หน่วยเล็กสุดไม่สามารถเปลี่ยนแปลงค่านี้ได้">\n'
                    :
                    '<input type="number" min="1" name="quantity_of_smallest_unit" value="' + data.pivot.quantity_of_smallest_unit + '" class="form-control input-sm text-right" oninput="Product2Edit.Unit.checkValueMinOne(this)">\n'
            ) +
            '    </td>\n' +
            '    <td>\n' +
            '        <input type="text" name="volume" value="' + (data.pivot.volume ? data.pivot.volume : '') + '" class="form-control input-sm text-right">\n' +
            '    </td>\n' +
            '    <td>\n' +
            '            <div class="input-group">' +
            '                <span class="input-group-addon">฿</span>' +
            '                <input type="number" min="0" name="label_price" value="' + (data.pivot.label_price ? data.pivot.label_price : 0) + '" class="form-control input-sm text-right">\n' +
            '            </div>' +
            '    </td>\n' +
            '    <td style="text-align: center; padding-top: 15px">\n' +
            '        <input type="checkbox" name="is_retail" ' + (data.not_retail ? '' : 'checked') + '>\n' +
            '    </td>\n' +
            '    <td style="text-align: right">\n' +
            '        <button type="button" class="btn btn-danger btn-sm" onclick="Product2Edit.Unit.delete(' + index + ')">\n' +
            '            ลบ\n' +
            '        </button>\n' +
            '    </td>\n' +
            '</tr>';
    },

    receiveData: function () {
        var product = Product2Edit.data.product;

        this.data.units = [];

        // Fetch data form table row
        var _this = this, _index = 0;
        this.tableTarget.children('tr').each(function () {
            var _tr = $(this);
            var _id = _tr.attr('data-id');
            var _unit = _this.unit(_tr.find('select[name=unit_id]').val());
            if (!_unit) return;

            var pivot = {
                id: parseInt(_id),
                company_id: parseInt(_tr.attr('data-company')),
                product_id: product.id,
                unit_id: _unit.id,
                quantity_of_smallest_unit: parseInt(_tr.find('input[name=quantity_of_smallest_unit]').val()),
                volume: _tr.find('input[name=volume]').val(),
                label_price: parseFloat(_tr.find('input[name=label_price]').val()),
                not_retail: _tr.find('input[name=is_retail]').is(':checked') ? 0 : 1,
                is_smallest_unit: (parseInt(_this.smallestTarget.val()) === _unit.id) ? 1 : 0
            };

            //product view data
            product.units[_index++] = {
                new: _tr.is('#new-unit'),
                not_retail: pivot.not_retail,
                pivot: pivot
            };

            //data submit to api
            _this.data.units.push(pivot);
        });

        //this.render();
    },

    init: function () {
        this.smallestTarget = $('#product-edit-unit-smallest');
        this.tableTarget = $('#product-edit-unit-list');
        this.tabBar = $('li a[href="#unit"]');
        this.tabPane = $('.tab-pane#unit');

        this.smallestTarget.on('change', function () {
            if (confirm('หน่วยที่เลือกจะถูกเปลี่ยนอัตราเทียบหน่วยนับสต็อกเป็น 1 ยืนยันการเปลี่ยนหน่วยเล็กที่สุดหรือไม่?')) {
                console.log('smallest units changed');
                this.receiveData();
                this.change(true);
            }
            this.render();
            return false;
        }.bind(this));

        this.tabPane.on('change', 'input, select', function (event) {
            console.log('units data changed');
            this.receiveData();
            this.change(true);
            if (event.target.type !== 'number') {
                console.log('units re-render');
                this.render();
            }
        }.bind(this));

        this.change(false);
    },

    //Search unit in the tools list
    unit: function (id) {
        var rawUnits = Product2Edit.tools.units;
        var _unit = null;
        rawUnits.forEach(function (unit) {
            if (unit.id === parseInt(id)) _unit = unit;
        });
        return _unit;
    },

    units: function (onlyAvailable, includeID) {
        var rawUnits = Product2Edit.tools.units;

        if (onlyAvailable) {
            var product = Product2Edit.data.product;
            var _units = [];

            rawUnits.forEach(function (unit) {
                var haveInProduct = false;

                product.units.forEach(function (pUnit) {
                    if (pUnit.pivot.unit_id === unit.id && !(includeID === unit.id)) haveInProduct = true;
                });

                if (haveInProduct) {
                    if (includeID) {
                        var newData = $.extend({}, unit, {disable: true});
                        _units.push(newData);
                    }
                } else {
                    _units.push(unit);
                }
            });

            return _units;
        } else {
            return rawUnits;
        }
    },

    add: function () {
        var product = Product2Edit.data.product;
        var unit = this.units(true)[0];

        if (unit) {
            product.units.push({
                id: unit.id,
                new: true,
                pivot: {
                    id: 0,
                    company_id: Product2Edit.tools.company.id,
                    product_id: product.id,
                    unit_id: unit.id,
                    quantity_of_smallest_unit: 1,
                    volume: '',
                    label_price: 0,
                    not_retail: 0,
                    is_smallest_unit: product.units.length === 0 ? 1 : 0
                }
            });

            this.change(true);
            this.render();
        }
    },

    delete: function (index) {
        if (confirm('การลบหน่วยสินค้าควรทำอย่างละมัดระวัง ข้อมูลสต๊อกสินค้าที่อ้างอิงหน่วยนี้อาจเสียหายถาวรได้')) {
            var product = Product2Edit.data.product;

             // check units length
             if(product.units.length > 1){
                product.units.splice(index, 1);
                this.change(true);
            }else{
                alert('สินค้าต้องมีอย่างน้อย 1 หน่วย');
                this.change(false);
            }      
            
            this.render();
        }
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.units = $.extend(true, [], Product2Edit.data.productOrigin.units);
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.data.units = [];

        this.change(false);
        this.render();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            this.renderSmallestUnitHTML();
            this.renderListHTML();
        } else {
            this.reset();
        }
    },

    //Append option to smallest unit select
    renderSmallestUnitHTML: function () {
        //Clear html
        this.smallestTarget.html('');

        var product = Product2Edit.data.product;

        //If data list have some data
        if (product.loaded && product.units && product.units.length) {
            var _this = this;

            //Foreach data list, Append data to HTML
            product.units.forEach(function (data) {
                var unit = _this.unit(data.pivot.unit_id);
                _this.smallestTarget.append('<option value="' + unit.id + '" ' + (data.pivot.is_smallest_unit ? 'selected' : '') + '>' + unit.name + '</option>');
            });

        } else {
            //No data html
            this.smallestTarget.append('<option value="">ไม่มีหน่วยสินค้า</option>');
        }
    },

    //Append table row to html
    renderListHTML: function () {
        //Clear html
        this.tableTarget.html('');

        var product = Product2Edit.data.product;

        //If data list have some data
        if (product.loaded && product.units && product.units.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            product.units.forEach(function (data) {
                if (data.pivot.is_smallest_unit)
                    data.pivot.quantity_of_smallest_unit = 1;

                if (data.pivot.company_id || !Product2Edit.protected)
                    _this.tableTarget.append(_this.tableRowOwnerHTML(data, _index++));
                else
                    _this.tableTarget.append(_this.tableRowHTML(data, _index++));
            });

        } else {
            //No data html
            this.tableTarget.append('<tr><td colspan="6" style="text-align: center">ไม่มีข้อมูล</td></tr>');
        }
    }
};
