var arincareShoppingCart = function () {
    var data = {
        product_id: 0,
        prodcut_name: '',
        list: [],
        quantity: 0
    };

    const _createThumbnail = function(data) {
        var product = data.product;
        var image = data.images;
        var prices = data.catalog_prices;

        var imgTag = '';
        if(image.length > 0){
            var url = image[0].normal.url;
            imgTag = `<img src="${url}" alt="${product.name}" style="width:200px; height: 200px;"></img>`;
        }else{
            imgTag = `<img     
            src="/images/arincare-icon.svg"
            alt="${product.name}"
            style="width: 200px; height: 200px"/>`
        }
        return ` <div class="col-sm-6 col-md-3">
                    <a href="/companies/marketplace?q=${product.name}" style="text-decoration: none;">
                        <div class="thumbnail" style="padding: 9px 0 0 0">
                            ${imgTag}
                            <div class="caption">
                                <h2 
                                style="word-break: break-all;
                                overflow: hidden;
                                height: 50px;
                                font-size: 16px;">
                                    ${product.name}
                                </h2>
                            </div>
                            <button class="btn btn-block btn-success" style="border-radius: 0; padding: 8px 12px; font-size: 16px">${_renderPrice(prices)}</button>
                        </div>
                    </a>
                </div>`;
    }

    const _renderPrice = function(catalog_prices) {
        if (catalog_prices.length === 0) return `0 บาท`;

        const prices = catalog_prices.map(
            price => price.price_per_unit
        );

        if (prices.length === 1) {
            return `${prices[0]} บาท`;
        } else {
            const min = Math.min.apply(null, prices);
            const max = Math.max.apply(null, prices);
            return `${min} - ${max} บาท`;
        }
    }

    const _init = function(list) {

        if(list.total > 0) {
            var product = list.data[0].product;

            data.quantity = list.total;
            data.list = list.data;
            data.product_id = product.id;
            data.prodcut_name = product.name

            $('.arincare_shopping_cart_product_name').html(data.prodcut_name);
            $('#arincare_shopping_cart_quantity').html(data.quantity);

            $('#arincare_shopping_cart_content').empty();
            data.list.map(d => $('#arincare_shopping_cart_content').append(_createThumbnail(d)));
        }
    };
    
    return {
        show: function(product_id) {
            $('#preloader').show();
            $.ajax({
                url: `${API3_URL}companies/${COMPANY.slug}/arinlink/catalog/${product_id}/product-refer`,
                type: 'GET',
                data: {
                    page: 1,
                    limit: 4
                },
                success: res => {
                    _init(res.results);
                    $('#arincare_shopping_cart').modal('show');
                    
                },
                error: res => {
                    console.error(res);
                },
                complete: () => {
                    $('#preloader').fadeOut('slow');
                }
            });
            
        },
        hide: function() {
            $('#arincare_shopping_cart').modal('hide');
        }
    }
}();
