/**
 * Product2 javascript file
 * for product edit/create storage page
 */

Product2Edit.Storage = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        general: {},
        configuration: {},
        categories: [],
        safety_stocks: [],
    },

    /**
     * @return {string}
     */
    tableRowHTML: function (data, index, name, disable) {
        return '<div class="cat-list col-md-6">\n' +
            '    <label>' +
            '        <input type="checkbox" class="' + (disable ? 'protected' : '') + '" name="' + name + data.id + '" ' + (this.isChecked(data.id, name) ? 'checked="checked"' : '') + '>' +
            '        ' + data.name +
            '    </label>\n' +
            '</div>';
    },

    renderData: function () {
        var product = Product2Edit.data.product;

        this.tabPane.find('textarea[name=note]').val(product.note);
        this.tabPane.find('textarea[name=usage_note]').val(product.usage_note);
        // this.tabPane.find('input[name=stock_max]').val(product.configuration ? product.configuration.stock_max : '');
        // this.tabPane.find('input[name=stock_min]').val(product.configuration ? product.configuration.stock_min : '');
        this.tabPane.find('textarea[name=sales_note]').val(product.configuration ? product.configuration.sales_note : '');
        this.tabPane.find('textarea[name=purchase_note]').val(product.configuration ? product.configuration.purchase_note : '');

        var _index = 0;
        this.selectTargetGroups.each(function () {
            var element = $(this);
            var index = _index++;
            try {
                element.val(product.configuration.groups[index].id);
            } catch (e) {
                element.val('');
            }
        });
        this.selectTargetGroups.selectpicker('refresh');

        setTimeout(function () {
            if (product.manufacturer_id !== this.selectTargetManufacturer.val()) {
                this.selectTargetManufacturer.val(product.manufacturer_id);
                this.selectTargetManufacturer.selectpicker('refresh');
            }
            if (product.importer_id !== this.selectTargetImporter.val()) {
                this.selectTargetImporter.val(product.importer_id);
                this.selectTargetImporter.selectpicker('refresh');
            }
            if (product.distributor_id !== this.selectTargetDistributor.val()) {
                this.selectTargetDistributor.val(product.distributor_id);
                this.selectTargetDistributor.selectpicker('refresh');
            }
        }.bind(this), 0);
    },

    receiveData: function () {
        var p = Product2Edit.data.product;
        var g = this.data.general;
        var c = this.data.configuration;

        if (!p.configuration) p.configuration = {};
        if (!p.configuration.groups) p.configuration.groups = [];
        this.data.categories = [];
        p.categories = [];
        p.safety_stocks = [];
        this.data.safety_stocks = [];
        c.groups = [];

        //Fetch data form table row
        var _this = this;
        // Category
        this.tableTargetCat.find('input[type=checkbox]').each(function () {
            var _element = $(this);
            var checkID = parseInt(_element.attr('name').substr('category'.length));

            if (_element.is(':checked')) {
                //data submit to api
                _this.data.categories.push(checkID);
                p.categories.push({ id: checkID });
            }
        });

        this.tableTargetSafetyStock.find('tr').each((index, rawElement) => {
            const element = $(rawElement);
            const safetyStock = {
                id: +element.attr('data-id'),
                branch_id: +element.attr('data-branch-id'),
                product_id: p.id,
                unit_id: +element.find('select[name=unit]').val(),
                min: +element.find('input[name=min]').val(),
                max: +element.find('input[name=max]').val(),
            };

            p.safety_stocks.push(safetyStock);
            this.data.safety_stocks.push(safetyStock);
        });

        var _index = 0;
        this.selectTargetGroups.each(function () {
            var element = $(this);
            var val = element.val();
            var index = _index++;

            p.configuration.groups[index] = {
                id: val,
            };

            if (val) c.groups.push(parseInt(val));
        });

        p.note = g.note = this.tabPane.find('textarea[name=note]').val();
        p.usage_note = g.usage_note = this.tabPane.find('textarea[name=usage_note]').val();
        p.manufacturer_id = g.manufacturer_id = this.tabPane.find('select[name=manufacturer_id]').val();
        p.importer_id = g.importer_id = this.tabPane.find('select[name=importer_id]').val();
        p.distributor_id = g.distributor_id = this.tabPane.find('select[name=distributor_id]').val();

        // p.configuration.stock_max = c.stock_max = this.tabPane.find('input[name=stock_max]').val();
        // p.configuration.stock_min = c.stock_min = this.tabPane.find('input[name=stock_min]').val();
        p.configuration.sales_note = c.sales_note = this.tabPane.find('textarea[name=sales_note]').val();
        p.configuration.purchase_note = c.purchase_note = this.tabPane.find('textarea[name=purchase_note]').val();

        //this.render();
    },

    init: function () {
        this.tableTargetCat = $('#product-edit-category-list');
        this.tableTargetSafetyStock = $('#product-edit-safety-stock-list');
        this.selectTargetGroups = $('select.product-edit-groups-list');
        this.selectTargetManufacturer = $('#product-edit-manufacturer-list');
        this.selectTargetImporter = $('#product-edit-importer-list');
        this.selectTargetDistributor = $('#product-edit-distributor-list');
        this.tabPane = $('.tab-pane#storage');
        this.tabBar = $('li a[href="#storage"]');

        this.tabPane.on('change', 'input, select, textarea', function () {
            console.log('storage data changed');
            this.receiveData();
            this.change(true);
        }.bind(this));

        this.change(false);
    },

    isChecked: function (id, name) {
        var product = Product2Edit.data.product;
        var _checked = false;
        var _arrayName = '';

        if (name === 'category') _arrayName = 'categories';

        if (!product[_arrayName]) return false;

        product[_arrayName].forEach(function (data) {
            if (id === data.id) {
                _checked = true;
            }
        });

        return _checked;
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.configuration = $.extend(true, [], Product2Edit.data.productOrigin.configuration);
        Product2Edit.data.product.categories = $.extend(true, [], Product2Edit.data.productOrigin.categories);
        Product2Edit.data.product.safety_stocks = $.extend(true, [], Product2Edit.data.productOrigin.safety_stocks);
        Product2Edit.data.product.note = Product2Edit.data.productOrigin.note;
        Product2Edit.data.product.usage_note = Product2Edit.data.productOrigin.usage_note;
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.data.categories = [];
        this.data.configuration = {};

        this.change(false);
        this.render();
        autoTextAreaSize();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            //this.renderTools(); //render in Product2Edit.loadTools();
            this.renderCatListHTML();
            this.renderSafetyStockListHTML();
            this.renderData();
        } else {
            this.reset();
        }
    },

    renderTools: function () {
        this.renderGroupListHTML();
        this.renderManufacturerListHTML();
    },

    //Append table row to html
    renderCatListHTML: function () {
        //Clear html
        this.tableTargetCat.html('');

        //If data list have some data
        if (Product2Edit.tools.categories.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            Product2Edit.tools.categories.forEach(function (data) {
                _this.tableTargetCat.append(_this.tableRowHTML(data, _index++, 'category', true));
            });

        } else {
            //No data html
            this.tableTargetCat.append('ไม่มีข้อมูล');
        }

    },

    renderSafetyStockListHTML: function () {
        this.tableTargetSafetyStock.html('');

        const branches = Product2Edit.tools.company.branches;
        const product = Product2Edit.data.product;

        if (branches && product.loaded && product.units) {
            let smallestUnitId;

            product.units.forEach((unit) => {
                if (unit.is_smallest_unit) smallestUnitId = unit.id;
            });

            branches.forEach((branch) => {

                let safetyStock = {
                    id: null,
                    branch_id: branch.id,
                    unit_id: smallestUnitId,
                    min: 0,
                    max: 0,
                };

                product.safety_stocks.forEach(function (item) {
                    if (item.branch_id === branch.id) safetyStock = item;
                });

                let unitOptions = '';

                product.units.forEach((unit) => {
                    unitOptions += `<option value="${unit.id}" ${safetyStock.unit_id === unit.id && 'selected'}>${unit.name}</option>`;
                });

                this.tableTargetSafetyStock.append(`
                    <tr data-id="${safetyStock.id}" data-branch-id="${branch.id}">
                        <td>${branch.name}</td>
                        <td>
                            <input class="form-control text-right" type="number" step="1" min="0" name="max" value="${safetyStock.max}">
                        </td>
                        <td>
                            <input class="form-control text-right" type="number" step="1" min="0" name="min" value="${safetyStock.min}">
                        </td>
                        <td>
                            <select class="form-control" name="unit">
                                ${unitOptions}
                            </select>
                        </td>
                    </tr>
                `);
            });

        } else {
            //No data html
            this.tableTargetSafetyStock.append('<tr><td colspan="99" style="text-align: center">ไม่มีข้อมูล</td></tr>');
        }

    },

    renderGroupListHTML: function () {
        this.selectTargetGroups.each(function () {
            var element = $(this);

            //Clear html
            element.html('');

            if (Product2Edit.tools.company.product_groups.length) {

                element.append('<option value="">-- เลือกกลุ่มสินค้า --</option>');

                //Foreach data list, Append data to HTML
                Product2Edit.tools.company.product_groups.forEach(function (data) {
                    element.append('<option value="' + data.id + '">' + data.name + '</option>');
                });

                element.selectpicker('refresh');
            } else {
                //No data html
                element.append('');
            }
        });
    },

    renderManufacturerListHTML: function () {
        //Clear html
        this.selectTargetManufacturer.html('');
        this.selectTargetImporter.html('');
        this.selectTargetDistributor.html('');

        if (Product2Edit.tools.manufacturers.length) {
            var _this = this;

            _this.selectTargetManufacturer.append('<option value="">-- เลือกบริษัทผู้ผลิต --</option>');
            _this.selectTargetImporter.append('<option value="">-- เลือกบริษัทผู้นำเข้า --</option>');
            _this.selectTargetDistributor.append('<option value="">-- เลือกบริษัทผู้จัดจำหน่าย --</option>');

            //Foreach data list, Append data to HTML
            Product2Edit.tools.manufacturers.forEach(function (data) {
                _this.selectTargetManufacturer.append('<option value="' + data.id + '">' + data.name + '</option>');
                _this.selectTargetImporter.append('<option value="' + data.id + '">' + data.name + '</option>');
                _this.selectTargetDistributor.append('<option value="' + data.id + '">' + data.name + '</option>');
            });

            this.selectTargetManufacturer.selectpicker('refresh');
            this.selectTargetImporter.selectpicker('refresh');
            this.selectTargetDistributor.selectpicker('refresh');
        } else {
            //No data html
            this.selectTargetManufacturer.append('');
            this.selectTargetImporter.append('');
            this.selectTargetDistributor.append('');
        }
    },
};
