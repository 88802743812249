/**
 * Is element content empty?
 *
 * @return boolean
 */
function isEmpty( el ){
	return !$.trim(el.html())
}

/**
 * Check all required fields over the form.
 *
 * @param object form
 * @return boolean
 */
function checkRequiredFields(form) {
	var is_empty = false
	$('[required]', form).each(function(index, element) {
		if ( $(element).val() === '' ) {
			$(element).closest('.form-group').addClass('has-error has-feedback')
			$(element).after( $('<span />', { class: 'glyphicon glyphicon-remove form-control-feedback'}) )
		} else {
			$(element).closest('.form-group').removeClass('has-error has-feedback')
			$(element).closest('.form-group').find('.glyphicon.glyphicon-remove.form-control-feedback').remove()
		}
		is_empty = is_empty || ($(element).val() === '')
	})

	if ($('.has-error').length) {
		var id = $('.has-error').first().closest('.tab-pane').attr('id')
		$('[role=presentation] a[href=#'+id+']').click();
		$('.has-error').first().find('input, textarea, selete').focus()
		alert('กรุณากรอกข้อมูลให้ครบถ้วน');
	}

	return !is_empty
}

/**
 * Object to List Elements Convertor.
 *
 * @param 	Object/Array response
 * @return  Object <ul>
 */
function object2List(response)
{
	if ( $.isPlainObject(response) || $.isArray(response) ) {
		var ul = $('<ul />');
		$.each(response, function(index, value) {
			ul.append( object2List(value) );
		})
		return ul
	}

	return $('<li />').html(response)
}

/**
 * Create Alart section in to #response-section element.
 *
 * @param 	string 	type
 * @param 	N/A 	response
 * @return 	response.
 */
function notify(response, type)
{
	type = typeof type !== 'undefined' ? type : 'info';

	var closeBtn = $('<button />', { class: 'close', type : 'button'}).html($('<span />').html('&times;'));
	var alert = $('<div />', { class : 'alert alert-' + type, id : 'notify-alert' }).append( closeBtn );

	alert.append( object2List(response) );

	$('#response-section').html( alert );
}

/**
 * This function is getting parameter from url by name.
 *
 * @param 	string	name
 * @return 	string
 */
getUrlParameter = function(name, default_return)
{
	var default_return = typeof default_return !== 'undefined' ?  default_return : null;
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
	if ( results == null ) return default_return;
	else return results[1].replace('%2C', ',');
}

/**
 * This function is setting patameters to url by objects variable.
 *
 * @param 	string 	params
 * @return 	void
 */
setUrlParameters = function( params )
{
	const urlPatams = $.param( params );
	const current_url = window.location.pathname + (urlPatams != '' ? '?' : '' ) + urlPatams;
	window.history.replaceState(null, null, current_url);
}

$.inObject = function(value, object) {
	for (var i in object)
		if ( object[i] == value )
			return true;
	return false;
}

/**
 *
 *
 *
 */
getAmounts = function(tax_types, amount, tax_percentage)
{
	if ( $.type(tax_types) == 'undefined'
			&& $.type(tax_types) != 'string'
			&& tax_types.search(/no_vat|vat_included|vat_excluded/) == -1 )
		return false;
	if ( typeof amount == 'undefined'
			&& !$.isNumeric( amount ) )
		return false;
	if ( typeof tax_percentage == 'undefined'
			&& !$.isNumeric( tax_percentage ) )
		return false;

	var tax_amount = 0;
	var net_amount = 0;
	var total_amount = 0;
	tax_percentage = tax_percentage / 100;
	switch ( tax_types ) {
		case 'no_vat' :
			net_amount = amount;
			total_amount = amount;
			break;
		case 'vat_included' :
			total_amount = amount;
			net_amount = total_amount / ( 1 + tax_percentage );
			tax_amount = total_amount - net_amount;
			break;
		case 'vat_excluded' :
			net_amount = amount;
			tax_amount = net_amount * tax_percentage;
			total_amount = net_amount + tax_amount;
			break;
	}

	return {
		net_amount : net_amount,
		total_amount : total_amount,
		tax_amount : tax_amount,
		tax_percentage : tax_percentage,
	}
}
