/**
 * Product2 javascript file
 * for product edit/create barcode page
 */

Product2Edit.Barcode = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        barcodes: {}  // Data to update in product->barcode db, Will update in receiveData()
    },

    /** All render function have reference to [Product2Edit.data.product.barcodes] not in [this.data] */

    unitsOptionHTML: function (selectID) {
        var _html = '';

        this.units().forEach(function (unit) {
            _html += ('<option value="' + unit.id + '" ' + (selectID === unit.id ? 'selected ' : ' ') + (unit.disable ? 'disabled' : '') + '>' + unit.name + '</option>');
        });

        return _html;
    },

    /**
     * @return {string}
     */
    tableRowHTML: function (data, index) {
        return '<tr data-company="' + data.company_id + '">\n' +
            '    <td>\n' +
            '        <input type="hidden" name="id" value="' + data.id + '">' +
            '        <select name="unit" class="form-control input-sm" disabled>\n' +
            '            ' + this.unitsOptionHTML(data.unit_id) + '\n' +
            '        </select>\n' +
            '    </td>\n' +
            '    <td>\n' +
            '        <input type="text" name="barcode" value="' + data.barcode + '" class="form-control input-sm" disabled>\n' +
            '    </td>\n' +
            '    <td></td>\n' +
            '    <td class="td-barcode">\n' +
            '        <div>' +
            '            <svg id="barcode-' + index + '"></svg>' +
            '        </div>' +
            '    </td>\n' +
            '</tr>';
    },

    tableRowOwnerHTML: function (data, index) {
        return '<tr data-company="' + data.company_id + '">\n' +
            '    <td style="vertical-align: top;">\n' +
            '        <input type="hidden" name="id" value="' + data.id + '">' +
            '        <select name="unit" class="form-control input-sm">\n' +
            '            ' + this.unitsOptionHTML(data.unit_id) + '\n' +
            '        </select>\n' +
            '    </td>\n' +
            '    <td style="vertical-align: top;">\n' +
            '        <input type="text" name="barcode" value="' + data.barcode + '" class="form-control input-xs" oninput="Product2Edit.Barcode.ean13CheckDigit(this.value,' + index + ')">\n' +
            '        <span  id="' + index + '" style="display:none;color:#D9534F">* บาร์โค้ดผิด Format </span>'+
            '    </td>\n' +
            '    <td style="white-space: nowrap;vertical-align: top;">\n' +
            '        <button type="button" class="btn btn-primary btn-xs" onclick="Product2Edit.Barcode.generateBarcode(' + index + ')">\n' +
            '            สร้างบาร์โค้ด ' +
            '        </button>\n' +
            '        <button type="button" class="btn btn-danger btn-xs" onclick="Product2Edit.Barcode.delete(' + index + ')">\n' +
            '            ลบ\n' +
            '        </button>\n' +
            '    </td>\n' +
            '    <td class="td-barcode"  style="vertical-align: top;">\n' +
            '        <div>' +
            '            <svg id="barcode-' + index + '"></svg>' +
            '        </div>' +
            '    </td>\n' +
            '</tr>'
            ;
    },

    receiveData: function () {
        var product = Product2Edit.data.product;

        this.data.barcodes = [];

        // Fetch data form table row
        var _this = this, _index = 0;
        this.tableTarget.children('tr').each(function () {
            var _tr = $(this);
            var unit_id = parseInt(_tr.find('select[name=unit]').val());
            if (!unit_id) return;

            var barcodeData = {
                id: _tr.find('input[name=id]').val(),
                company_id: parseInt(_tr.attr('data-company')),
                unit_id: unit_id,
                product_id: product.id,
                product_unit_id: _this.pUnit(unit_id).pivot.id,
                barcode: _tr.find('input[name=barcode]').val()
            };

            //product view data
            product.barcodes[_index++] = barcodeData;

            //data submit to api
            if (barcodeData.company_id) _this.data.barcodes.push(barcodeData);
        });

        //this.render();
        this.renderBarcode();
    },

    init: function () {
        this.tableTarget = $('#product-edit-barcode-list');
        this.tabBar = $('li a[href="#barcode"]');

        $('.tab-pane#barcode').on('change', 'input, select, textarea', function () {
            console.log('barcode data changed');
            this.receiveData();
            this.change(true);
        }.bind(this));

        this.change(false);
    },

    // get product_unit by unit_id
    pUnit: function (unit_id) {
        var product = Product2Edit.data.product;
        var _pUnits = [];

        product.units.forEach(function (data) {
            if (data.pivot.unit_id === unit_id) _pUnits = data;
        });

        return _pUnits;
    },

    // get only units have in product unit
    units: function () {
        var rawUnits = Product2Edit.tools.units;
        var product = Product2Edit.data.product;
        var _units = [];

        rawUnits.forEach(function (unit) {
            product.units.forEach(function (pUnit) {
                if (pUnit.pivot.unit_id === unit.id) _units.push(unit);
            });
        });

        return _units;
    },

    add: function () {
        var product = Product2Edit.data.product;
        var unit = this.units()[product.barcodes.length % product.units.length];

        if (unit) {
            product.barcodes.push({
                id: 0,
                company_id: Product2Edit.tools.company.id,
                unit_id: unit.id,
                barcode: ''
            });

            this.change(true);
            this.render();
        } else {
            $.notify({
                message: 'บาร์โค้ดจำเป็นต้องอ้างอิงจากหน่วยสินค้า กรุณาเพิ่มหน่วยสินค้าหน่วยสินค้าก่อน'
            }, {
                type: 'warning',
                placement: {
                    from: "top",
                    align: "center"
                },
                offset: 50
            });
        }
    },

    delete: function (index) {
        var product = Product2Edit.data.product;

        product.barcodes.splice(index, 1);

        this.change(true);
        this.render();
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.barcodes = $.extend(true, [], Product2Edit.data.productOrigin.barcodes);
        this.data.reference_code = Product2Edit.data.reference_code + '';
        $( "#barcode-error" ).css({"display": "none"});
        this.data.barcodes = [];

        this.change(false);
        this.render();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            this.renderListHTML();
            this.renderBarcode();
        } else {
            this.reset();
        }
    },

    //Append table row to html
    renderListHTML: function () {
        //Clear html
        this.tableTarget.html('');

        var product = Product2Edit.data.product;

        //If data list have some data
        if (product.loaded && product.barcodes && product.barcodes.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            product.barcodes.forEach(function (data) {
                var index = _index++;

                if (data.company_id || !Product2Edit.protected)
                    _this.tableTarget.append(_this.tableRowOwnerHTML(data, index));
                else
                    _this.tableTarget.append(_this.tableRowHTML(data, index));

            });

        } else {
            //No data html
            this.tableTarget.append('<tr><td colspan="4" style="text-align: center">ไม่มีข้อมูล</td></tr>');
        }
    },

    renderBarcode: function () {
        var product = Product2Edit.data.product;
        var _this = this;

        //If data list have some data
        if (product.loaded && product.barcodes && product.barcodes.length) {
            var _index = 0;

            //Foreach data list, render barcode
            product.barcodes.forEach(function (data) {
                if (data.barcode){
                    _this.ean13CheckDigit(data.barcode,_index);
                    JsBarcode('#barcode-' + _index++, data.barcode, {
                        background: 'rgba(0,0,0,0)',
                        margin: 4,
                        fontSize: 9,
                        width: 1,
                        height: 24
                    });
                }
            });

        }
    },

    /** Barcode generate function **/
    generateBarcode: function (index) {
        var product = Product2Edit.data.product;

        var barcode = '885' + this.padNumber(Product2Edit.tools.company.id, 4) + this.getRandomInt(0, 99999);
        barcode = barcode + this.eanCheckDigit(barcode);

        product.barcodes[index].barcode = barcode;
        
        this.render();
    },

    padNumber: function (n, len) {
        var s = n.toString();
        if (s.length < len) {
            s = ('0000000000' + s).slice(-len);
        }
        return s;
    },

    eanCheckDigit: function (s) {
        var result = 0;
        for (counter = s.length - 1; counter >= 0; counter--) {
            result = result + parseInt(s.charAt(counter)) * (1 + (2 * (counter % 2)));
        }
        return (10 - (result % 10)) % 10;
    },

    getRandomInt: function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    ean13CheckDigit: function(barcode,index){
        var barcode = barcode.toString().split("");
        var isNumber = true;
        barcode.forEach((element) => { 
            if(isNaN(parseInt(element))){
                isNumber = false;
                console.log(parseInt(element));
            }
         } )
        if(isNumber === false){
            $( "span#"+index ).css({"display": "block"});
            $( "#barcode-error" ).css({"display": "block"}); 
        }else  if(barcode.length > 13){
            $( "span#"+index ).css({"display": "block"});
            $( "#barcode-error" ).css({"display": "block"});            
        }else if(barcode.length == 13){
            const even_sum = parseInt(barcode[1])+parseInt(barcode[3])+parseInt(barcode[5])+parseInt(barcode[7])+parseInt(barcode[9])+parseInt(barcode[11]);   
            const even_sum_three = even_sum*3;
            const odd_sum = parseInt(barcode[0])+parseInt(barcode[2])+parseInt(barcode[4])+parseInt(barcode[6])+parseInt(barcode[8])+parseInt(barcode[10]);
            const total_sum = even_sum_three+odd_sum;
            const next_ten = (Math.ceil(total_sum/10))*10;
            const check_digit = next_ten-total_sum;
            if(parseInt(barcode[12]) != check_digit){
                $( "span#"+index ).css({"display": "block"});
                $( "#barcode-error" ).css({"display": "block"});
                
            }else{
                $( "span#"+index ).css({"display": "none"});
                $( "#barcode-error" ).css({"display": "none"});
            }            
        }else{
            $( "span#"+index ).css({"display": "none"});
            $( "#barcode-error" ).css({"display": "none"});
        }   
    },    
};
