const checkTokenExpire = (t = 600000) => {
    const pathname = window.location.pathname;
    if (pathname.endsWith('print') || pathname.endsWith('report')) return;

    setInterval(() => {
        $.ajax({
            url: `${API3_URL}check-token-expire`,
            type: 'get',
            success: (res) => {
                if (res.expired === undefined || res.expired) {
                    window.postMessage({ id: 'tokenExpire' }, window.location.origin);
                }
            },
            error: () => {
                window.postMessage({ id: 'tokenExpire' }, window.location.origin);
            },
        });
    }, t);
};

const checkTermsAndCondition = () => {
    $.ajax({
        url: `${API3_URL}me/accept-consent`,
        type: 'get',
        data: {
            company_slug: COMPANY.slug,
            consent_type: 'PDPA',
            consent_version:USER_CONSENT_VERSION,
        },
        success: (res) => {
            if (res.code === '200' && !Boolean(res.is_accept)) {
                window.postMessage({ id: 'termsAndCondition' }, window.location.origin);
            }
        },
    });
};
