/**
 * Product2 javascript file
 * for product edit/create print page
 */

Product2Edit.Print = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        labels: {}
    },
    errors: [],

    renderData: function () {
        var labels = Product2Edit.data.product.labels;
        const product = Product2Edit.data.product
        this.tabPane.find('textarea[name=volume_intake]').val(labels ? labels.volume_intake : '');
        this.tabPane.find('textarea[name=unit_intake]').val(labels ? labels.unit_intake : '');
        this.tabPane.find('textarea[name=daily_frequency_intake]').val(labels ? labels.daily_frequency_intake : '');
        this.tabPane.find(`select[name=taken_time]`).val(labels && labels.taken_time ? labels.taken_time : 0);
        this.tabPane.find('input[name=taken_morning]').prop("checked", labels ? labels.taken_morning : false);
        this.tabPane.find('input[name=taken_noon]').prop("checked", labels ? labels.taken_noon : false);
        this.tabPane.find('input[name=taken_evening]').prop("checked", labels ? labels.taken_evening : false);
        this.tabPane.find('input[name=taken_before_bed]').prop("checked", labels ? labels.taken_before_bed : false);
        this.tabPane.find('input[name=taken_symptom_occur]').prop("checked", labels ? labels.taken_symptom_occur : false);
        this.tabPane.find('input[name=is_taken_other]').prop("checked", labels ? labels.is_taken_other : false);
        this.tabPane.find('textarea[name=taken_other]').val(labels ? labels.taken_other : '');
        this.tabPane.find('input[name=is_note]').prop("checked", labels ? labels.is_note : false)
        this.tabPane.find('textarea[name=note]').val(labels ? labels.note : '');
        this.tabPane.find('textarea[name=symptom_occur]').val(labels ? labels.symptom_occur : '');
        this.tabPane.find('textarea[name=dosage_instruction]').val(labels ? labels.dosage_instruction : '');
        this.tabPane.find('textarea[name=drug_label_information]').val(labels ? labels.drug_label_information : '');
        this.tabPane.find('input[name=drug_name]').val(product.name ? product.name : '');
        this.renderExternalUse()
    },

    receiveData: function () {
        var p = Product2Edit.data.product;

        if (!p.labels) p.labels = {};
        p.labels.volume_intake = this.tabPane.find('textarea[name=volume_intake]').val();
        p.labels.unit_intake = this.tabPane.find('textarea[name=unit_intake]').val();
        p.labels.daily_frequency_intake = this.tabPane.find('textarea[name=daily_frequency_intake]').val();
        p.labels.taken_morning = this.tabPane.find('input[name=taken_morning]').is(':checked') ? 1 : 0;
        p.labels.taken_noon = this.tabPane.find('input[name=taken_noon]').is(':checked') ? 1 : 0;
        p.labels.taken_evening = this.tabPane.find('input[name=taken_evening]').is(':checked') ? 1 : 0;
        p.labels.taken_before_bed = this.tabPane.find('input[name=taken_before_bed]').is(':checked') ? 1 : 0;
        p.labels.taken_symptom_occur = this.tabPane.find('input[name=taken_symptom_occur]').is(':checked') ? 1 : 0;
        p.labels.note = this.tabPane.find('textarea[name=note]').val();
        p.labels.taken_time = this.tabPane.find('select[name=taken_time]').val();
        p.labels.is_taken_other = this.tabPane.find('input[name=is_taken_other]').is(':checked') ? 1 : 0;
        p.labels.taken_other = this.tabPane.find('textarea[name=taken_other]').val();
        p.labels.is_note = this.tabPane.find('input[name=is_note]').is(':checked') ? 1 : 0
        p.labels.symptom_occur = this.tabPane.find('textarea[name=symptom_occur]').val();
        p.labels.dosage_instruction = this.tabPane.find('textarea[name=dosage_instruction]').val();
        p.labels.drug_label_information = this.tabPane.find('textarea[name=drug_label_information]').val();

        this.data.labels = p.labels;
        //this.render();
    },

    init: function () {
        this.tabPane = $('.tab-pane#print');
        this.tabBar = $('li a[href="#print"]');

        this.tabPane.on('change', 'input, select, textarea', function () {
            this.receiveData();
            this.change(true);
        }.bind(this));

        this.change(false);        
    },

    submit: function () {
        if (this.errors.length > 0) {
            const errors = [...new Set(this.errors)]
            this.handleError(errors)
            $.notify({
                message: 'บันทึกไม่สำเร็จ'
            }, {
                type: 'danger',
                placement: {
                    from: "top",
                    align: "center"
                },
                offset: 50
            });
            return false
        } else {
            $('#error_display').removeClass('show')
            this.receiveData();

            Product2Edit.submit(this.data, function () {
                this.change(false);
            }.bind(this));
        }

    },

    handleError(errors) {
        const limits = {
            patient_name: {
                label: "ชื่อผู้ใช้ยา",
                limit: 30
            },
            drug_name: {
                label: "ชื่อยา",
                limit: 30
            },
            dosage_instruction: {
                label: "ข้อบ่งใช้",
                limit: 30
            },
            unit: {
                label: "หน่วย",
                limit: 3
            },
            volume_intake: {
                label: "รับประทานครั้งละ",
                limit: 4
            },
            unit_intake: {
                label: "หน่วยทาน",
                limit: 10
            },
            daily_frequency_intake:{
                label: "วันละ",
                limit: 3 
            },
            taken_symptom_occur: {
                label: "ทุกๆ",
                limit: 26 
            },
            symptom_occur: {
                label: "เฉพาะเมื่อมีอาการ",
                limit: 25 
            },
            note: {
                label: "อื่นๆ",
                limit: 40 
            },
            taken_other: {
                label: 'ทุกๆ',
                limit: 3
            }
        }
        $('#error_display').addClass('show')
        let errorMessage = '<ul>';
        errors.forEach((error) => {
            console.log(error)
            errorMessage += `<li>${limits[error].label} ใส่ตัวอักษรได้ไม่เกิน ${limits[error].limit}  อักษร</li>`
        })
        errorMessage += '</ul>'
        $('#error_list').html(errorMessage)
    },

    reset: function () {
        Product2Edit.data.product.labels = $.extend(true, {}, Product2Edit.data.productOrigin.labels);
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.data.labels = {};

        this.change(false);
        this.render();
        autoTextAreaSize();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            this.renderData();
        } else {
            this.reset();
        }
    },

    isNumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[0-9,/.-/\b]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    },

    renderExternalUse() {
        const isExternalUse = this.tabPane.find('select[name=taken_time]').val() == 3
        if(isExternalUse) {
            $('#display_volumn_intake').text('ใช้ครั้งละ :')
            $('#display_unit_intake').text('หน่วย :')
        } else {
            $('#display_volumn_intake').text('รับประทานครั้งละ :')
            $('#display_unit_intake').text('หน่วยทาน :')
        }
    },

    checkLimit: function (e,v) {

        const name = e.attr('name')
        const value = e.val()
        const limits = {
            patient_name: 30,
            drug_name: 30,
            dosage_instruction: 30,
            unit: 4,
            unit_intake: 10,
            daily_frequency_intake: 3,
            taken_symptom_occur: 26,
            symptom_occur: 25,
            note: 40,
            taken_other: 3
        }

        if (typeof(limits[name]) != 'undefined') {
            const cleanString = this.exceptString(value)
            if (cleanString.length > limits[name]) {
                e.addClass('is-overflow')
                this.errors.push(name)
            } else {
                e.removeClass('is-overflow')
                const filterd = this.errors.filter((item) => {
                    return item != name
                })
                this.errors = filterd
            }
        }
    },
    isNumberWithForamt(e, limit) {
        let regex =/^[0-9,-\/]{1,4}$/g;
        if (limit == 3) {
            regex =/^[0-9,-\/]{1,3}$/g;
        }

        const name = e.attr("name")
        const value = e.val()
        const test = regex.test(value)
        if (!test && value != '') {
            e.addClass('is-overflow')
            this.errors.push(name)   
        } else {
            e.removeClass('is-overflow')
            const filterd = this.errors.filter((item) => {
                return item != name
            })
            this.errors = filterd
        }
    },
    exceptString(str) {
        return str.replace(/[|ิ|ี|ื|ึ|์|ิ|่|้|๊|๋|็|ู|ุ|ํ|ั|"]/g,'')
    }
};
