/**
 * Product2 javascript file
 * for product edit/create price page
 */

Product2Edit.Price = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        price_sets: {}  // Data to update in product->priceSets db, Will update in receiveData()
    },

    /** All render function have reference to [Product2Edit.data.product.price_sets] not in [this.data] */

    branchOptionHTML: function (selectID) {
        var _html = '';
        this.branches().forEach(function (branch) {
            _html += ('<option value="' + branch.id + '" ' + (selectID === branch.id ? 'selected ' : ' ') + (branch.disable ? 'disabled' : '') + '>' + branch.name + '</option>');
        });
        return _html;
    },

    unitsOptionHTML: function (branchID, selectID) {
        var _html = '';

        this.units(branchID, selectID).forEach(function (unit) {
            _html += ('<option value="' + unit.id + '" ' + (selectID === unit.id ? 'selected ' : ' ') + (unit.disable ? 'disabled' : '') + '>' + unit.name + '</option>');
        });

        return _html;
    },

    /**
     * @return {string}
     */
    tableRowHTML: function (data, index) {
        var branchName = this.branch(data.branch_id).name;

        return '<tr class="' + (data.new ? 'checked new' : '') + '" data-company="' + data.company_id + '" id="' + (data.new ? 'new-unit' : 'old-unit') + '">\n' +
            '    <td>\n' +
            '        <input type="hidden" name="id" value="' + data.id + '">' +
            '        <select name="branch" class="form-control input-sm" ' + (data.new ? '' : 'style="display: none"') + '>\n' +
            '            ' + this.branchOptionHTML(data.branch_id) + '\n' +
            '        </select>\n' +
            (data.new ? '' : '<h2 style="display:inline-block; min-width: 192px">' + branchName + '</h2>') +
            '    </td>\n' +
            '    <td>\n' +
            '        <select name="unit" class="form-control input-sm" ' + (data.new ? '' : 'style="display: none"') + '>\n' +
            '            ' + this.unitsOptionHTML(data.branch_id, data.unit_id) + '\n' +
            '        </select>\n' +
            (data.new ? '' : '<h2 style="display:inline-block;">' + this.unit(data.unit_id).name + '</h2>') +
            '    </td>\n' +
            function (data) {
                var html = '';
                for (var i = 1; i <= 5; i++) {
                    html += '<td>\n' +
                        '        <input type="number" name="price' + i + '" value="' + data['price' + i] + '" class="form-control input-sm text-right">\n' +
                        '    </td>\n';
                }
                return html;
            }(data) +
            '    <td>\n' +
            '        <button type="button" class="btn btn-danger btn-xs" onclick="Product2Edit.Price.delete(' + index + ')">\n' +
            '            ลบ\n' +
            '        </button>\n' +
            '    </td>\n' +
            '</tr>';
    },

    receiveData: function () {
        var product = Product2Edit.data.product;

        this.data.price_sets = [];

        // Fetch data form table row
        var _this = this, _index = 0;
        this.tableTarget.children('tr').each(function () {
            var _tr = $(this);
            var unit_id = parseInt(_tr.find('select[name=unit]').val());
            if (!unit_id) return;

            var priceSetData = {
                id: _tr.find('input[name=id]').val(),
                new: _tr.is('#new-unit'),
                company_id: parseInt(_tr.attr('data-company')),
                branch_id: parseInt(_tr.find('select[name=branch]').val()),
                unit_id: unit_id,
                product_id: product.id,
                product_unit_id: _this.pUnit(unit_id).pivot.id,
                price1: parseFloat(_tr.find('input[name=price1]').val()),
                price2: parseFloat(_tr.find('input[name=price2]').val()),
                price3: parseFloat(_tr.find('input[name=price3]').val()),
                price4: parseFloat(_tr.find('input[name=price4]').val()),
                price5: parseFloat(_tr.find('input[name=price5]').val())
            };

            //product view data
            product.price_sets[_index++] = priceSetData;

            //data submit to api
            _this.data.price_sets.push(priceSetData);
        });

        //this.render();
    },

    init: function () {
        this.tableTarget = $('#product-edit-price-list');
        this.tabBar = $('li a[href="#price"]');

        $('.tab-pane#price').on('change', 'input, select, textarea', function (event) {
            console.log('price_sets data changed');
            this.receiveData();
            this.change(true);
            if (event.target.type !== 'number') {
                console.log('price_sets re-render');
                this.render();
            }
        }.bind(this));

        this.change(false);
    },

    // get all branches with null
    branches: function () {
        var branches = Product2Edit.tools.company.branches;
        return [{
            id: null,
            name: 'ทุกสาขา'
        }].concat(branches);
    },

    // get branch
    branch: function (branch_id) {
        var branches = Product2Edit.tools.company.branches;
        var _branch = {
            id: null,
            name: 'ทุกสาขา'
        };
        branches.forEach(function (branch) {
            if (branch.id === branch_id) _branch = branch;
        });
        return _branch;
    },

    //Search unit in the tools list
    unit: function (id) {
        var rawUnits = Product2Edit.tools.units;
        var _unit = null;
        rawUnits.forEach(function (unit) {
            if (unit.id === parseInt(id)) _unit = unit;
        });
        return _unit;
    },

    // get product_unit by unit_id
    pUnit: function (unit_id) {
        var product = Product2Edit.data.product;
        var _pUnits = [];

        product.units.forEach(function (data) {
            if (data.pivot.unit_id === unit_id) _pUnits = data;
        });

        return _pUnits;
    },

    // get only units have in product unit
    units: function (branchID, includeID) {
        var rawUnits = Product2Edit.tools.units;
        var product = Product2Edit.data.product;
        var _units = [];

        rawUnits.forEach(function (unit) {
            product.units.forEach(function (pUnit) {
                if (pUnit.pivot.unit_id === unit.id) {
                    var haveInPriceSets = false;

                    product.price_sets.forEach(function (pSet) {
                        if (pSet.branch_id === branchID && pSet.unit_id === unit.id && !(includeID === unit.id)) haveInPriceSets = true;
                    });

                    if (haveInPriceSets) {
                        if (includeID) {
                            var newData = $.extend({}, unit, {disable: true});
                            _units.push(newData);
                        }
                    } else {
                        _units.push(unit);
                    }
                }
            });
        });

        return _units;
    },

    add: function () {
        var product = Product2Edit.data.product;
        var countAllUnits = product.units.length;
        var branch = null;

        if (!product.units.length) {
            $.notify({ message: 'ราคาจำเป็นต้องอ้างอิงจากหน่วยสินค้า กรุณาเพิ่มหน่วยสินค้าหน่วยสินค้าก่อน' }, { type: 'warning' });
            return;
        }

        // Select branch having not full unit price sets
        this.branches().forEach(function (_branch) {
            if (!branch) {
                var countBranchUnits = 0;
                product.price_sets.forEach(function (pSet) {
                    if (pSet.branch_id === _branch.id) countBranchUnits++;
                });
                if (countBranchUnits < countAllUnits) branch = _branch;
            }
        });

        // Return if full price sets
        if (!branch) {
            $.notify({
                message: 'เพิ่มราคาครบทุกสาขาและทุกหน่วยแล้ว'
            }, {
                type: 'info',
                placement: {
                    from: "top",
                    align: "center"
                },
                offset: 50
            });
            return;
        }

        var unit = this.units(branch.id)[0];

        if (!unit) {
            $.notify({ message: 'เพิ่มราคาครบทุกหน่วยแล้ว' }, { type: 'info' });
            return;
        }

        product.price_sets.push({
            id: 0,
            new: true,
            company_id: Product2Edit.tools.company.id,
            branch_id: branch.id,
            unit_id: unit.id,
            product_id: product.id,
            product_unit_id: this.pUnit(unit.id).pivot.id,
            price1: 0,
            price2: 0,
            price3: 0,
            price4: 0,
            price5: 0
        });

        this.change(true);
        this.render();
    },

    delete: function (index) {
        var product = Product2Edit.data.product;

        product.price_sets.splice(index, 1);

        this.change(true);
        this.render();
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.price_sets = $.extend(true, [], Product2Edit.data.productOrigin.price_sets);
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.data.price_sets = [];

        this.change(false);
        this.render();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            this.renderListHTML();
        } else {
            this.reset();
        }
    },

    //Append table row to html
    renderListHTML: function () {
        //Clear html
        this.tableTarget.html('');

        var product = Product2Edit.data.product;

        //If data list have some data
        if (product.loaded && product.price_sets && product.price_sets.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            product.price_sets.forEach(function (data) {
                _this.tableTarget.append(_this.tableRowHTML(data, _index++));
            });

        } else {
            //No data html
            this.tableTarget.append('<tr><td colspan="8" style="text-align: center">ไม่มีข้อมูล</td></tr>');
        }
    }

};
