$(function(){
	// callUploaderIV();
	// callUploaderLP();
	// callUploaderPR();

	$('.image').fancybox({
	  helpers: {
	    overlay: {
	      locked: false
	    }
	  }
	});
});

function callUploaderLP(){
    $('#fine-uploader-manual-trigger').fineUploader({
        template: 'qq-template-manual-trigger',
        request: {
	    	method : 'POST',
	    	customHeaders: {
		        'Authorization': 'Bearer ' + $.cookie('jwt')
		    },
		    params : {'company_id' : (typeof COMPANY != 'undefined') ? COMPANY.id : null},
	        endpoint: API2_URL+"uploader/endpoint"
	    },
        callbacks: {
            onComplete: function(id, name, response) {
                //Licensed pharmacies
            	//console.log(id);
            	//console.log(name);
            	//console.log(response);
                saveInfo(id,name,response,'LP');
            },
            onDelete: function(id) {
                this.setDeleteFileParams({filename: this.getName(id)}, id);
            }
        },
        deleteFile: {
	        enabled: true,
	    	customHeaders: {
	    		'Authorization': 'Bearer ' + $.cookie('jwt')
		    },
	        method: "DELETE",
	        endpoint: API2_URL+"uploader/endpoint"
	    },
	    thumbnails: {
	        placeholders: {
	            waitingPath: '/placeholders/waiting-generic.png',
	            notAvailablePath: '/placeholders/not_available-generic.png'
	        }
	    },
        validation: {
	    	allowedExtensions: ['jpeg', 'jpg', 'png', 'gif'],
	        itemLimit: 1,
	        sizeLimit: 3145728 // 3 M = 3072 * 1024 bytes
	    },
	    messages: {
	    	noFilesError: "ไม่มีไฟล์ที่อัปโหลด",
	    	tooManyItemsError: "ไฟล์ที่ถูกอัปโหลดมากเกินกำหนด ({netItems}) จำกัดจำนวนไฟล์คือ {itemLimit}"
	    },
        autoUpload: false
    });

    $('#trigger-upload').click(function() {
        $('#fine-uploader-manual-trigger').fineUploader('uploadStoredFiles');
    });
}

function callUploaderPR(){
    $('#fine-uploader-validation').fineUploader({
        template: 'qq-template-validation',
        request: {
	    	method : 'POST',
	    	customHeaders: {
	    		'Authorization': 'Bearer ' + $.cookie('jwt')
		    },
		    params : {'company_id' : (typeof COMPANY != 'undefined') ? COMPANY.id : null},
	        endpoint: API2_URL+"uploader/endpoint"
	    },
        callbacks: {
            onComplete: function(id, name, response) {
                //Professional
                saveInfo(id,name,response,'PR');
            },
            onDelete: function(id) {
                this.setDeleteFileParams({filename: this.getName(id)}, id);
            }
        },
        deleteFile: {
	        enabled: true,
	    	customHeaders: {
	    		'Authorization': 'Bearer ' + $.cookie('jwt')
		    },
	        method: "DELETE",
	        endpoint: API2_URL+"uploader/endpoint"
	    },
        thumbnails: {
	        placeholders: {
	            waitingPath: '/placeholders/waiting-generic.png',
	            notAvailablePath: '/placeholders/not_available-generic.png'
	        }
	    },
        validation: {
	    	allowedExtensions: ['jpeg', 'jpg', 'png', 'gif'],
	        itemLimit: 1,
	        sizeLimit: 3145728 // 3 M = 3072 * 1024 bytes
	    },
	    messages: {
	    	noFilesError: "ไม่มีไฟล์ที่อัปโหลด",
	    	tooManyItemsError: "ไฟล์ที่ถูกอัปโหลดมากเกินกำหนด ({netItems}) จำกัดจำนวนไฟล์คือ {itemLimit}"
	    },
        autoUpload: false
    });
    $('#validation-upload').click(function() {
        $('#fine-uploader-validation').fineUploader('uploadStoredFiles');
    });
}

function callUploaderIV(){
    $('#fine-uploader-manual-trigger-iv').fineUploader({
        template: 'qq-template-manual-trigger-iv',
        request: {
	    	method : 'POST',
		    params : {'company_id' : (typeof COMPANY != 'undefined') ? COMPANY.id : null},
	        endpoint: API2_URL+"uploader/endpoint"
	    },
        callbacks: {
            onComplete: function(id, name, response) {
                //Licensed pharmacies
            	//console.log(id);
            	//console.log(name);
            	//console.log(response);
                saveInfo(id,name,response,'IV');
            },
            onDelete: function(id) {
                this.setDeleteFileParams({filename: this.getName(id)}, id);
            }
        },
        deleteFile: {
	        enabled: true,
	    	customHeaders: {
	    		'Authorization': 'Bearer ' + $.cookie('jwt')
		    },
	        method: "DELETE",
	        endpoint: API2_URL+"uploader/endpoint"
	    },
	    thumbnails: {
	        placeholders: {
	            waitingPath: '/placeholders/waiting-generic.png',
	            notAvailablePath: '/placeholders/not_available-generic.png'
	        }
	    },
        validation: {
	    	allowedExtensions: ['jpeg', 'jpg', 'png', 'gif'],
	        itemLimit: 1,
	        sizeLimit: 3145728 // 3 M = 3072 * 1024 bytes
	    },
	    messages: {
	    	noFilesError: "ไม่มีไฟล์ที่อัปโหลด",
	    	tooManyItemsError: "ไฟล์ที่ถูกอัปโหลดมากเกินกำหนด ({netItems}) จำกัดจำนวนไฟล์คือ {itemLimit}"
	    },
        autoUpload: false
    });

    $('#trigger-upload-iv').click(function() {
        $('#fine-uploader-manual-trigger-iv').fineUploader('uploadStoredFiles');
    });
}

function saveInfo(id,name,response,type){
	console.log(type);
	$.ajax({
		method : 'POST',
		url : API2_URL + 'upload/save-info',
		data : {
				'company_id' : (typeof COMPANY != 'undefined') ? COMPANY.id : null,
				'document_id': (typeof COMPANY != 'undefined') ? COMPANY.id : null,
				'document_type': type,
				'order_id':id,
				'original_name':name,
				'file_type':response.file_type,
				'name':response.name,
				'type':response.type,
				'upload_path':response.upload_path,
				'user_id': EMPLOYEE.user_id
				},
		success : function(r) {
			// console.log(response);
			var filepath = 'images/'+r.upload_path+'/'+r.name;
			var imagepath = (r.file_type == 'file') ? BASE_URL+'placeholders/not_available-generic.png' : API2_URL+filepath;
			var html = '<div class="col-sm-12" style="margin-left: 15px;">'+
				        	'<div class="col-sm-5">'+
				        	  '<a href="'+API2_URL+filepath+'" class="thumbnail image" title="'+r.original_name+'" target="_blank">'+
				              	'<img class="qq-thumbnail-selector img-responsive" src="'+imagepath+'">'+
				              '</a>'+
				              '</div>'+
				        	'<div class="col-sm-6"><i>'+r.original_name+'</i></div>'+
				        	'<div class="col-sm-1"><img onclick="getDelete('+r.id+',\''+response.uuid+'\', \''+type+'\');" src="'+BASE_URL+'placeholders/trash.gif" style="cursor: pointer;" title="ลบไฟล์"></div>'+
				    	'</div>';
		    if(type == 'LP'){
		    	callUploaderLP();
		    }
		    if(type == 'PR'){
		    	callUploaderPR();
		    }
		    if(type == 'IV'){
		    	callUploaderIV();
		    }
		    $("#block-"+type).show();
			$("#block-"+type).html(html);
		}
	});
}

function getDelete(id, uuid, type){
	if(confirm("คุณต้องการลบไฟล์ ?")){
		$.ajax({
			method : 'POST',
			url : API2_URL + 'upload/delete-file',
			data : {'id' : id, 'type' : type },
			success : function(response) {
				$("#block-"+type).slideUp("normal", function() { $(this).empty(); } );
			}
		});
	}
}
