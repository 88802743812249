/**
 * Sidebar for tab page
 * use in product edit/create page
 *
 * by ninja
 */

var sidebarTab = {

    //Sidebar element
    element: null,

    //Use to calculation sidebar sticky
    sidebarHeight: null,
    sidebarWidth: null,
    sidebarOffset: null,

    /**
     * Call in page use this sidebar
     * @elementName is name of sidebar jquery selector
     * or leave blank to use default $('.nav-sidebar') and $('.tab-page-group')
     */
    init: function (elementName) {

        //Check elementName
        this.element = elementName ? $(elementName) : $('.nav-sidebar');

        //First time calculation
        this.calSidebar();
        this.onScroll();

        //Add event listener
        $(window).resize(this.calSidebar.bind(this)); //Cal again on window resize
        $(window).scroll(this.onScroll.bind(this));//Listening  page scroll event
    },

    //Calculate sidebar size to use on sticky action
    calSidebar: function () {

        this.sidebarHeight = this.element.outerHeight();
        this.sidebarWidth = this.element.width();
        this.sidebarOffset = this.element.offset();

    },

    //Listener onScroll event
    onScroll: function () {
        /**
         * Calculation to set|unset sticky sidebar
         * It will be 'true' when sidebar over the top of screen
         */
        if (window.innerWidth >= 992 && window.pageYOffset > this.sidebarOffset.top - 100 ) {
            this.setSticky();
        } else {
            this.unsetSticky();
        }
    },

    //Set sticky sidebar
    setSticky: function () {
        this.element.addClass('sticky');
        this.element.width(this.sidebarWidth);
        this.element.css('left', this.sidebarOffset.left);
    },

    //Unset sticky sidebar
    unsetSticky: function () {
        this.element.removeClass('sticky');
        this.element.width('auto');
        this.element.css('left', 'inherit');
    }
};