var productBarcode = {
		fetch : function (modal) {
			$(modal).modal({
				backdrop : 'static'
			});
		},
		showEditModal : function (modal){
			var setting_id = $("#select-list").val();
			if(!setting_id){
				return;
			}
			$.get(BASE_URL + 'companies/product-barcodes/' + setting_id + '/edit',null ,function(response){
				$("#id-edit").val(response.barcode.id);
				$("#edit-name").val(response.barcode.name);
				$("#hidden-name").val(response.barcode.name);
				
				var option_format = '';
				$("#edit-format").html($("#edit-format > option"));
				$("#edit-format > option").each(function(i,n){
					if($(n).val() == response.barcode.format){
						option_format += '<option value="'+$(n).val()+'" selected="selected">'+$(n).text()+'</option>';
					}else{
						option_format += '<option value="'+$(n).val()+'">'+$(n).text()+'</option>';
					}
				});
				$("#edit-format").html(option_format);
				$("#edit-format").selectpicker('refresh');
				$("#edit-size").val(response.barcode.size);
				$("#edit-margin_left").val(response.barcode.margin_left);
				$("#edit-margin_top").val(response.barcode.margin_top);
				$("#edit-columns").val(response.barcode.columns);
				$("#edit-padding_left").val(response.barcode.padding_left);
				$("#edit-padding_right").val(response.barcode.padding_right);
				$("#edit-padding_top").val(response.barcode.padding_top);
				$("#edit-padding_bottom").val(response.barcode.padding_bottom);
				$("#edit-font_size").val(response.barcode.font_size);
				
				if(response.barcode.type_barcode){
					$("#edit-type_barcode").prop( "checked", true );
				}else{
					$("#edit-type_barcode").prop( "checked", false );
				}
				
				if(response.barcode.page == 'P'){
					$("#edit-page-P").prop( "checked", true );
				}else{
					$("#edit-page-L").prop( "checked", true );
				}		
				
				if(response.barcode.show_name){
					$("#edit-show_name").prop( "checked", true );
				}else{
					$("#edit-show_name").prop( "checked", false );
				}			
				
				var option_type_name = '';
				$("#edit-type_name > option").each(function(i,n){
					if($(n).val() == response.barcode.type_name){
						option_type_name += '<option value="'+$(n).val()+'" selected="selected">'+$(n).text()+'</option>';
					}else{
						option_type_name += '<option value="'+$(n).val()+'">'+$(n).text()+'</option>';
					}
				});
				$("#edit-type_name").html(option_type_name);
				$("#edit-type_name").selectpicker('refresh');
				
				if(response.barcode.show_price){
					$("#edit-show_price").prop( "checked", true );
				}else{
					$("#edit-show_price").prop( "checked", false );
				}			
				
				var option_type_price = '';
				$("#edit-type_price > option").each(function(i,n){
					if($(n).val() == response.barcode.type_price){
						option_type_price += '<option value="'+$(n).val()+'" selected="selected">'+$(n).text()+'</option>';
					}else{
						option_type_price += '<option value="'+$(n).val()+'">'+$(n).text()+'</option>';
					}
				});
				$("#edit-type_price").html(option_type_price);
				$("#edit-type_price").selectpicker('refresh');
				
				if(response.barcode.show_barcode){
					$("#edit-show_barcode").prop( "checked", true );
				}else{
					$("#edit-show_barcode").prop( "checked", false );
				}	

				if(response.barcode.border){
					$("#edit-border").prop( "checked", true );
				}else{
					$("#edit-border").prop( "checked", false );
				}	
				
				$(modal).modal({
					backdrop : 'static'
				});
			});			
		},
		deleteFormat : function () {
			var setting_id = $("#select-list").val();
			if(!setting_id){
				return;
			}
			if(confirm("คุณต้องการลบรูปแบบการพิมพ์ ?")){
				$.ajax({
					  headers : {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
					  },
					  method: "DELETE",
					  url: BASE_URL + 'companies/product-barcodes/delete/'+setting_id
				}).done(function( response ) {
					var opt = '<select class="form-control selectpicker" id="select-list" onchange="getPrintList(this);">';
						opt+='<option value="">-- เลือกรายการตั้งค่า --</option>';
						$(response.data).each(function(i, n){
							opt += '<option value="'+n.id+'">'+n.name+'</option>';
						});
						opt += '</select>';
						$("#block-listoption").html(opt);
						var text = '<div style="margin: 10px 10px 10px 10px;"><strong>สถานะการบันทึก</strong><br/>'+response.response+'</div>';
						$.notify(text, {
							type : 'success',
							placement : { align : 'center' }
						});
						$("#content_details").empty();
				});
			}
	   }
};