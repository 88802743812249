/**** PRODUCT PURCHASE HISTORY FUNCTION BEGINS ***/
function loadProductPurchaseHistory(company_id, product_id, product_name, viewing_supplier_id){
	return new Promise((resolve, reject) => {
	$.ajax({
		url: API2_URL + 'companies/' + company_id +'/products/' + product_id + '/purchase-history',
		type: 'GET',
		dataType: 'JSON',
		data: {
			'company_id' : company_id,
			'product_id' : product_id
		},
		success: function(data){
			if(data != null && data.purchase_history_records != null){
				$('#product-purchase-history-viewing-supplier_id').val(viewing_supplier_id);
				$('#modal_product_purchase_history_product_name').text(product_name);
				reloadProductPurchaseHistoryDataTable(data.purchase_history_records);
				$('#product-purchase-history-modal').modal('show');
				resolve(data);
			} else {
				reject(new Error('No purchase history records found.'));
			}
		},
		error: function(xhr, status, error){
			console.error("Error loading purchase history:", error);
			$.notify('<div style="margin: 10px 10px 10px 10px;"><strong>สถานะการทำงาน: </strong><br/>เกิดข้อผิดพลาดในการเข้าถึงข้อมูลประวัติการซื้อสินค้า<br/></div>',
					{
						z_index: 9999,
						type: 'danger'
					}
			);
			reject(error);
		}
	});//$.ajax*/
	});
}//searchSuppliers

function initProductPurchaseHistoryModal(){
	var data_table = $('#product_purchase_history_data_table').DataTable({
		columns: [
				{data: 'purchased_at', 'width':'80px'}
				,{data: 'gr_reference_code', 'width':'80px'}
				,{data: 'branch_name', 'width':'80px'}
				,{data: 'supplier_reference_code', 'width':'80px'}
				,{data: 'supplier_name', 'width':'500px'}
				,{data: 'stock_in_quantity', 'width':'50px'}
				,{data: 'free_quantity', 'width':'50px'}
				,{data: 'unit_name', 'width':'70px'}
				,{data: 'price_per_unit', 'width':'80px'}
		],
		searching: false,
		retrieve: true,
		"order": [[ 0, "desc" ]],
		"pagingType": "full_numbers",
		"lengthMenu": [[5], [5]],
		"createdRow": function ( row, data, index ) {
			if(data.supplier_id == parseInt($('#product-purchase-history-viewing-supplier_id').val())){
				$('td', row).eq(4).prop('style', 'font-weight: bold; color: red');
			}
			if(data.supplier_reference_code == null || data.supplier_reference_code.trim() == ""){
				$('td', row).eq(3).html('-');
			}
			if(data.supplier_name == null || data.supplier_name.trim() == ""){
				$('td', row).eq(4).html('-');
			}
			$('td', row).eq(1).html('<a target="_blank" href="/companies/goods-receipts/'+ data.gr_id + '/edit">'+ data.gr_reference_code + '</a>');
			$('td', row).eq(5).addClass('text-right');
			$('td', row).eq(6).addClass('text-right');
			$('td', row).eq(7).addClass('text-center');
			$('td', row).eq(8).addClass('text-right');
		}
	});
}//initSearchSupplierModal

function reloadProductPurchaseHistoryDataTable(data){
	$('#product_purchase_history_data_table').DataTable().clear();
	$('#product_purchase_history_data_table').DataTable().rows.add(data);
	$('#product_purchase_history_data_table').DataTable().draw();
}//renderReportDataTable

/**** SEARCH SUPPLIERS FUNCTION ENDS ***/