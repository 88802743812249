/**
 * ==============================
 *
 * Common & Custom Product
 *
 * ==============================
 */

$(document).ready(function() {
	var retail_unit_id = $('.radio-is_retail_sales_unit[value=1]:checked').closest('tr').data('unit-id');
	$('#select-unit-btn').on('click', function() {
		$.ajax({
			url : API_URL+'/unit/'+$('#unit-selector').val(),
            headers: null,
			method : 'GET',
			success : function(data) {
				$('#has-no-unit').fadeOut('slow');
				$('#unit-form-target').append(data.html).hide().fadeIn('slow');
				var option = $('#unit-selector option:selected');
				option.prop('disabled', true).prop('selected', false);
				$('#unit-selector').selectpicker('refresh');
			}
		})
	});

	$(document).on('click', '.unit-remove-button', function() {
		var unit_id = $(this).data('unit-id');
		$(this).closest('tr').fadeOut('slow', function() {
			$(this).remove();

			if ( $('.radio-is_retail_sales_unit[value=1]').length ) {
				var tr = $('.radio-is_retail_sales_unit[value=1]').closest('tr');
				var unit_id = tr.data('unit-id');

				retail_unit_id = unit_id;

				$('#retail_unit_name').html( tr.data('unit-name') );
				$('[name=barcode]').val( $('#input-unit-barcode-'+unit_id).val() );
			} else {
				retail_unit_id = 0;
				$('[name=barcode]').val('');
				$('#retail_unit_name').html( 'กรุณาเลือกหน่วยสำหรับขาย' );
			}

			if ( !$('.radio-is_retail_sales_unit').length )
				$('#has-no-unit').fadeIn('slow');
		});

		$('#unit-selector option[value='+unit_id+']').prop('disabled', false);
		$('#unit-selector').selectpicker('refresh');
		$('#unit-selector').first().prop('selected', true);
	});
	$(document).on('change', '.radio-is_smallest_unit', function() {
		var clicked = $(this);
		if (clicked.val() == 1) {
			$('.radio-is_smallest_unit').each(function(index, value) {
				if( $(this).val() == 0 && $(this).attr('name') != clicked.attr('name'))
					$(this).click();
			});
		}
	});
	$(document).on('change', '.radio-is_retail_sales_unit', function() {
		var clicked = $(this);
		if (clicked.val() == 1) {
			var tr = clicked.closest('tr');
			var unit_id = tr.data('unit-id');

			retail_unit_id = unit_id;

			$('#retail_unit_name').html( tr.data('unit-name') );
			$('[name=barcode]').val( $('#input-unit-barcode-'+unit_id).val() );

			$('.radio-is_retail_sales_unit').each(function(index, value) {
				if( $(this).val() == 0 && $(this).attr('name') != clicked.attr('name'))
					$(this).click();
			});
		} else {
			if ( !$('.radio-is_retail_sales_unit[value=1]:checked').length ) {
				retail_unit_id = 0;
				$('[name=barcode]').val('');
				$('#retail_unit_name').html( 'กรุณาเลือกหน่วยสำหรับขาย' );
			}
		}
	});
	$(document).on('change', '.input-unit-barcode', function() {
		$('[name=barcode]').val( $('#input-unit-barcode-'+retail_unit_id).val() );
	});
});

(function(){
	arincare.controller('ProductController',
		function($scope, $http, $templateCache) {
			$scope.method = 'GET';

			$scope.fetch = function(url, modal) {
				$scope.url = url;
				$scope.modal = modal;

				$http({
					method: $scope.method,
					url: $scope.url,
					cache: $templateCache
				}).then(function(response) {
					$scope.product = response.data;
					var remaining = $scope.product.remaining_quantity;
					angular.forEach($scope.product.units, function(unit, key) {
						qty = Math.floor( remaining / unit.pivot.quantity_of_smallest_unit );
						remaining = remaining % unit.pivot.quantity_of_smallest_unit;
						unit.real_quantity = qty;

						if ( unit.pivot.is_smallest_unit )
							$scope.smallest_unit = unit;
					});

					$($scope.modal).modal({
						backdrop : 'static'
					});
					// $($scope.modal).modal('show')
				}, function(response) {
					$scope.data = response.data || "Request failed";
					$scope.status = response.status;
				});
			};

			$scope.updateModel = function(method, url) {
				$scope.method = method;
				$scope.url = url;
			};
		}
	)
})();
