/**
 * Product2 javascript file
 * for product edit/create supplier page
 */

Product2Edit.Supplier = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        suppliers: []
    },
    toolsRendered: false,

    tableRowSupplierHTML: function (data, index) {
        return '<tr data-id="' + data.id + '">\n' +
            '    <td>' + data.name + '</td>\n' +
            '    <td>\n' +
            '        <button type="button" class="btn btn-danger btn-xs" onclick="Product2Edit.Supplier.deleteSupplier(' + index + ')">\n' +
            '            ลบ\n' +
            '        </button>\n' +
            '    </td>\n' +
            '    <td></td>\n' +
            '</tr>';
    },

    receiveData: function () {
        var product = Product2Edit.data.product;

        this.data.suppliers = [];

        // Fetch data form table row
        var _this = this, _index = 0;
        this.tableSupplierTarget.find('tr').each(function () {
            var _row = $(this);

            var supID = parseInt(_row.attr('data-id'));

            if (supID) {
                _this.data.suppliers.push(supID);

                var supplier = _this.supplier(supID);

                product.suppliers[_index++] = {
                    'id': supID,
                    'name': supplier.name
                };
            }
        });

        //this.render();
    },

    init: function () {
        this.tableSupplierTarget = $('#product-edit-supplier-list');
        this.selectSupplierTarget = $('#product-edit-supplier-id');
        this.tabBar = $('li a[href="#supplier"]');

        $('.tab-pane#supplier').on('change', 'input, select, textarea', function () {
            console.log('supplier data changed');
            this.receiveData();
            this.change(true);
        }.bind(this));

        this.change(false);
    },

    supplier: function (id) {
        var _supplier = null;

        Product2Edit.tools.company.suppliers.forEach(function (supplier) {
            if (supplier.id === id) _supplier = supplier;
        });

        return _supplier;
    },

    addSupplier: function () {
        var product = Product2Edit.data.product;

        var supID;
        if (supID = this.selectSupplierTarget.val()) {
            supID = parseInt(supID);

            var supplier = this.supplier(supID);

            product.suppliers.push({
                'id': supplier.id,
                'name': supplier.name
            });

            this.renderSupplierListHTML();
        }
    },

    deleteSupplier: function (index) {
        var product = Product2Edit.data.product;

        product.suppliers.splice(index, 1);

        this.renderSupplierListHTML();
    },

    isChecked: function (id, name) {
        var product = Product2Edit.data.product;
        var _checked = false;
        var _arrayName = '';

        if (name === 'treatment') _arrayName = 'treatment_classes';
        if (name === 'report') _arrayName = 'bureau_of_drug_controls';

        if (!product[_arrayName]) return false;

        product[_arrayName].forEach(function (data) {
            if (id === data.id) {
                _checked = true;
            }
        });

        return _checked;
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.suppliers = $.extend(true, [], Product2Edit.data.productOrigin.suppliers);
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.change(false);
        this.render();
        autoTextAreaSize();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            //this.renderTools(); //render in Product2Edit.loadTools();
            this.renderSupplierListHTML();
            //this.renderData();
        } else {
            this.reset();
        }
    },

    renderTools: function () {
        this.renderSupplierSelectListHTML();
    },

    renderSupplierSelectListHTML: function () {
        //Clear html
        this.selectSupplierTarget.html('');

        if (Product2Edit.tools.company.suppliers.length) {
            var _this = this;

            _this.selectSupplierTarget.append('<option value="">-- เลือกผู้จัดจำหน่าย --</option>');

            //Foreach data list, Append data to HTML
            Product2Edit.tools.company.suppliers.forEach(function (data) {
                _this.selectSupplierTarget.append('<option value="' + data.id + '">' + data.name + '</option>');
            });

            this.selectSupplierTarget.selectpicker('refresh');
        } else {
            //No data html
            this.selectSupplierTarget.append('');
        }
    },

    renderSupplierListHTML: function () {
        //Clear html
        this.tableSupplierTarget.html('');

        var product = Product2Edit.data.product;

        if (product.loaded && product.suppliers && product.suppliers.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            product.suppliers.forEach(function (data) {
                _this.tableSupplierTarget.append(_this.tableRowSupplierHTML(data, _index++));
            });

        } else {
            //No data html
            this.tableSupplierTarget.append('<tr><td colspan="2" style="text-align: center">ไม่มีข้อมูล</td><td></td></tr>');
        }
    }
};