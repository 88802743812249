/**
 * Product2 javascript file
 * for product edit/create medical information page
 */

Product2Edit.MInfo = {

    data: { // Data send to api in submit()
        reference_code: '',
        changed: false,
        ingredients: {},
        treatment_classes: [],
        bureau_of_drug_controls: [],
        print_report: []
    },
    toolsRendered: false,

    /**
     * @return {string}
     */
    tableRowHTML: function (data, index, name, disable) {
        return '<div class="cat-list col-md-6">\n' +
            '    <label>' +
            '        <input type="checkbox" class="' + (disable ? 'protected' : '') + '" name="' + name + data.id + '" ' + (this.isChecked(data.id, name) ? 'checked="checked"' : '') + '>' +
            '        ' + data.name +
            '    </label>\n' +
            '</div>';
    },

    tableRowIngredientHTML: function (data, index, disable) {
        return '<tr data-id="' + data.id + '" data-company="' + data.pivot.company_id + '" >\n' +
            '    <td>' + data.name + '</td>\n' +
            '    <td><input type="text" name="volume" class="form-control input-sm text-right" value="' + data.pivot.volume + '" ' + (disable ? 'disabled' : '') + '></td>\n' +
            '    <td>\n' +
            (!disable ?
            '        <button type="button" class="btn btn-danger btn-xs" onclick="Product2Edit.MInfo.deleteIngredient(' + index + ')">\n' +
            '            ลบ\n' +
            '        </button>\n'
            : '') +
            '    </td>\n' +
            '    <td></td>\n' +
            '</tr>';
    },

    receiveData: function () {
        var product = Product2Edit.data.product;

        this.data.ingredients = {};
        this.data.bureau_of_drug_controls = [];
        this.data.print_report = [];
        this.data.treatment_classes = [];
        product.treatment_classes = [];
        product.bureau_of_drug_controls = [];
        product.print_report = [];

        // Fetch data form table row
        var _this = this, _index = 0;
        this.tableIngredientTarget.find('tr').each(function () {
            var _row = $(this);

            var ingID = parseInt(_row.attr('data-id'));

            if (ingID) {
                var company_id =  parseInt(_row.attr('data-company'));
                var volume = _row.find('input[name=volume]').val();

                if (company_id) {
                    _this.data.ingredients[ingID] = {
                        company_id: company_id,
                        volume: volume
                    };
                }

                var ingredient = _this.ingredient(ingID);

                product.ingredients[_index++] = {
                    'id': ingID,
                    'name': ingredient.name,
                    'pivot': {
                        'company_id': company_id,
                        'volume': volume
                    }
                };
            }
        });

        // Report
        this.tableTargetReport.find('input[type=checkbox]').each(function () {
            var _element = $(this);
            var checkID = parseInt(_element.attr('name').substr('report'.length));

            if (_element.is(':checked')) {
                //data submit to api
                _this.data.bureau_of_drug_controls.push(checkID);
                product.bureau_of_drug_controls.push({id: checkID});
            }
        });

        // Print report
        this.tableTargetPrintReport.find('input[type=checkbox]').each(function () {
            var _element = $(this);
            var checkID = parseInt(_element.attr('name').substr('print_report'.length));

            if (_element.is(':checked')) {
                //data submit to api
                _this.data.print_report.push(checkID);
                product.print_report.push({id: checkID});
            }
        });

        // Treatment
        this.tableTargetTreatment.find('input[type=checkbox]').each(function () {
            var _element = $(this);
            var checkID = parseInt(_element.attr('name').substr('treatment'.length));

            if (_element.is(':checked')) {
                //data submit to api
                _this.data.treatment_classes.push(checkID);
                product.treatment_classes.push({id: checkID});
            }
        });

        //this.render();
    },

    init: function () {
        this.tableIngredientTarget = $('#product-edit-ingredient-list');
        this.selectIngredientTarget = $('#product-edit-ingredient-id');
        this.tableTargetReport = $('#product-edit-report-list');
        this.tableTargetPrintReport = $('#product-edit-print-report-list');
        this.tableTargetTreatment = $('#product-edit-treatment-list');
        this.tabBar = $('li a[href="#medical-info"]');

        $('.tab-pane#medical-info').on('change', 'input, select, textarea', function () {
            console.log('medical-info data changed');
            this.receiveData();
            this.change(true);
        }.bind(this));

        this.change(false);
    },

    ingredient: function (id) {
        var _ingredient = null;

        Product2Edit.tools.ingredients.forEach(function (ingredient) {
            if (ingredient.id === id) _ingredient = ingredient;
        });

        return _ingredient;
    },

    addIngredient: function () {
        var product = Product2Edit.data.product;

        var ingID;
        if (ingID = this.selectIngredientTarget.val()) {
            ingID = parseInt(ingID);

            var ingredient = this.ingredient(ingID);

            product.ingredients.push({
                'id': ingredient.id,
                'name': ingredient.name,
                'pivot': {
                    'company_id': Product2Edit.tools.company.id,
                    'volume': ''
                }
            });

            this.renderIngredientListHTML();
            Product2Edit.handleChangeGenericName();
        }
    },

    deleteIngredient: function (index) {
        var product = Product2Edit.data.product;

        product.ingredients.splice(index, 1);

        this.renderIngredientListHTML();
        Product2Edit.handleChangeGenericName();
    },

    isChecked: function (id, name) {
        var product = Product2Edit.data.product;
        var _checked = false;
        var _arrayName = '';

        if (name === 'treatment') _arrayName = 'treatment_classes';
        if (name === 'report') _arrayName = 'bureau_of_drug_controls';
        if (name === 'print_report') _arrayName = 'print_reports';

        if (!product[_arrayName]) return false;

        product[_arrayName].forEach(function (data) {
            if (id === data.id) {
                _checked = true;
            }
        });

        return _checked;
    },

    submit: function () {
        this.receiveData();

        Product2Edit.submit(this.data, function () {
            this.change(false);
        }.bind(this));
    },

    reset: function () {
        Product2Edit.data.product.ingredients = $.extend(true, [], Product2Edit.data.productOrigin.ingredients);
        Product2Edit.data.product.bureau_of_drug_controls = $.extend(true, [], Product2Edit.data.productOrigin.bureau_of_drug_controls);
        Product2Edit.data.product.print_report = $.extend(true, [], Product2Edit.data.productOrigin.print_report);
        Product2Edit.data.product.treatment_classes = $.extend(true, [], Product2Edit.data.productOrigin.treatment_classes);
        this.data.reference_code = Product2Edit.data.reference_code + '';

        this.change(false);
        this.render();
        autoTextAreaSize();
    },

    change: function (changed) {
        this.data.changed = changed;
        Product2Edit.change();

        if (changed) {
            this.tabBar.parent().addClass('changed');
            this.tabBar.find('span').text('**');
        } else {
            this.tabBar.parent().removeClass('changed');
            this.tabBar.find('span').text('');
        }
    },

    render: function () {
        if (this.data.reference_code === Product2Edit.data.reference_code) {
            //this.renderTools(); //render in Product2Edit.loadTools();
            this.renderIngredientListHTML();
            this.renderBureauOfDrugControlListHTML();
            this.renderTreatmentListHTML();
            //this.renderData();
        } else {
            this.reset();
        }
    },

    renderTools: function () {
        this.renderIngredientSelectListHTML();
    },

    renderIngredientSelectListHTML: function () {
        //Clear html
        this.selectIngredientTarget.html('');

        if (Product2Edit.tools.ingredients.length) {
            var _this = this;

            _this.selectIngredientTarget.append('<option value="">-- เลือกส่วนผสม --</option>');

            //Foreach data list, Append data to HTML
            Product2Edit.tools.ingredients.forEach(function (data) {
                _this.selectIngredientTarget.append('<option value="' + data.id + '">' + data.name + '</option>');
            });

            this.selectIngredientTarget.selectpicker('refresh');
        } else {
            //No data html
            this.selectIngredientTarget.append('');
        }
    },

    renderIngredientListHTML: function () {
        //Clear html
        this.tableIngredientTarget.html('');

        var product = Product2Edit.data.product;

        if (product.loaded && product.ingredients && product.ingredients.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            product.ingredients.forEach(function (data) {
                _this.tableIngredientTarget.append(_this.tableRowIngredientHTML(data, _index++, !data.pivot.company_id));
            });

        } else {
            //No data html
            this.tableIngredientTarget.append('<tr><td colspan="3" style="text-align: center">ไม่มีข้อมูล</td><td></td></tr>');
        }
    },

    renderBureauOfDrugControlListHTML: function () {
        this.tableTargetReport.html('');
        this.tableTargetPrintReport.html('');

        if (Product2Edit.tools.bureauOfDrugControls.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            Product2Edit.tools.bureauOfDrugControls.forEach(function (data) {
                _this.tableTargetReport.append(_this.tableRowHTML(data, _index++, 'report', true));
                _this.tableTargetPrintReport.append(_this.tableRowHTML(data, _index++, 'print_report', false));
            });

        } else {
            //No data html
            this.tableTargetReport.html('ไม่มีข้อมูล');
            this.tableTargetPrintReport.html('ไม่มีข้อมูล');
        }
    },

    renderTreatmentListHTML: function () {
        this.tableTargetTreatment.html('');

        if (Product2Edit.tools.treatmentClasses.length) {
            var _this = this;
            var _index = 0;

            //Foreach data list, Append data to HTML
            Product2Edit.tools.treatmentClasses.forEach(function (data) {
                _this.tableTargetTreatment.append(_this.tableRowHTML(data, _index++, 'treatment', true));
            });

        } else {
            //No data html
            this.tableTargetTreatment.append('ไม่มีข้อมูล');
        }
    }
};
