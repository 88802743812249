const getNotifyCartExpire = () => {
    const currentDate = new Date().toISOString().substr(0, 10)

    if (localStorage.getItem('CART_EXPIRE_NOTIFY') !== currentDate) {
        $.ajax({
            url: `${API3_URL}companies/${COMPANY.slug}/arinlink/orders/notify-expire`,
            type: 'get',
            success: (res) => {
                localStorage.setItem('CART_EXPIRE_NOTIFY', currentDate)
                if (res.have_least_one) {
                    document.getElementById('marketplace-cart-expire-notify').style.display = 'block'
                }
            },
        })
    }
}
